"use client";

import { loadStoryblokBridge } from "@storyblok/js";
import { useState } from "react";

import StoryblokComponent from "../StoryblokComponent/StoryblokComponent";
import type { StoryblokStoryProps } from "./StoryblokStory";

const bridgeLoader = (setState: (newStory: any) => void) => {
  loadStoryblokBridge()
    .then(() => {
      const { StoryblokBridge, location } = window;
      const storyblokInstance = new StoryblokBridge();

      storyblokInstance.on(["published", "change"], () => {
        location.reload();
      });

      storyblokInstance.on(["input"], (e) => {
        setState(e?.story?.content);
      });
    })
    .catch((err) => console.error(err));
};

const ClientStoryblokStory = ({ story, ...restProps }: StoryblokStoryProps) => {
  const [blokState, setBlokState] = useState(story.content);
  bridgeLoader((newStory: typeof blokState) => setBlokState(newStory));

  return <StoryblokComponent blok={blokState} preview {...restProps} />;
};

export default ClientStoryblokStory;
