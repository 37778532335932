import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/app/products/[...category]/components/DenimBodyUpdater.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/_Link/_Link.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Accordion/Accordion.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Accordion/AccordionGroup.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/BlokWrap/BlokWrap.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Button/Button.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/ButtonLink/ButtonLink.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/InnerHTML/ClientInnerHTML.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Input/Input.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/NewsletterInput/NewsletterInput.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Price/Price.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/ProductCard/ProductCardAction.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/SearchBar/SearchBar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/ToolTip/ToolTip.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/atoms/Video/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/components/modifiers/AspectRatioProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/ArticleCategory/ArticleCategoryNavigation/Navigation.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/ArticlesShowcase/ArticlesShowcase.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/Media/Media.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/ProductListing/Slider/ProductSlider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/statics/header/HeaderBloks.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/TagSideBySide/ClientSideBySide.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/bloks/TagSideBySide/UI.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/storyblok/storyblokSetup/StoryblokComponent/ClientComponent/ClientComponent.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/storyblok/storyblokSetup/StoryblokComponent/ClientComponentWithFetcher/ClientComponentWithFetcher.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/camilla-pihl/src/lib/storyblok/storyblokSetup/StoryblokStory/ClientStoryblokStory.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@storyblok+react@2.4.8_react-dom@18.2.0_react@18.2.0/node_modules/@storyblok/react/dist/bridge-loader.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@storyblok+react@2.4.8_react-dom@18.2.0_react@18.2.0/node_modules/@storyblok/react/dist/story.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js")